import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from '@reduxjs/toolkit';
import locationReducer from './slices/locationSlice';
import missingWillReducer from './slices/missingWillSearchSlice';
import invoiceReducer from './slices/invoiceSlice';
import templateReducer from './slices/templateSlice';
import deathNoticeReducer from './slices/deathNoticeSlice';
import obituariesReducer from './slices/obituarySlice';
import testimonialSlice from './slices/testimonialSlice';
import charitySlice from './slices/charitySlice';
import charityPartnershipSlice from './slices/charityPartnershipSlice';
import charityCategorySlice from './slices/charityCategorySlice';
import pricingSlice from './slices/pricingSlice';
import usersSlice from './slices/userSlice';
import terminologySlice from './slices/terminologySlice';
import pagesSlice from './slices/pageSlice';
import questionSlice from './slices/questionSlice';
import answerReducer from './slices/answerSlice';
import metaSlice from './slices/metaSlice';
import livingWillFormSlice from './slices/livingWillFormSlice';
import willRegistrationSlice from './slices/willRegistrationSlice';
import faqSlice from './slices/faqSlice';
import dashboardSlice from './slices/dashboardSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import willCreatorSlice from './slices/willCreatorSlice';
import roleSlice from './slices/roleSlice';
import permissionSlice from './slices/permissionSlice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['form'],
};

const rootReducer = combineReducers({
  location: locationReducer,
  missingWill: missingWillReducer,
  invoice: invoiceReducer,
  template: templateReducer,
  deathNotice: deathNoticeReducer,
  obituaries: obituariesReducer,
  testimonial: testimonialSlice,
  charities: charitySlice,
  charityPartnerships: charityPartnershipSlice,
  charityCategories: charityCategorySlice,
  pricing: pricingSlice,
  users: usersSlice,
  terminologies: terminologySlice,
  pages: pagesSlice,
  answers: answerReducer,
  questions: questionSlice,
  meta: metaSlice,
  livingWillForm: livingWillFormSlice,
  willRegistration: willRegistrationSlice,
  faqs: faqSlice,
  willStatus: willCreatorSlice,
  dashboard: dashboardSlice,
  roles: roleSlice,
  permissions: permissionSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV === 'development',
});

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
