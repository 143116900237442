import { dtCustomStyle } from '../../helpers/DataTableHelper';
import { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useAppDispatch } from '../../redux/hooks';
import DeleteModal from './DeleteModal';
import { PageOptions } from '../../constants/Common';
import {
  IShowDeleteLivingWillFormModal,
  ILivingWillFormRow,
  ILivingWillFormPayload,
  ILivingWillFormColumn,
} from '../../datatypes/LivingWillForm';
import { deleteLivingWillForm } from '../../redux/slices/livingWillFormSlice';
import ActionButton from '../common/ActionButton';
import { canUserAccess } from 'src/helpers/common';

interface IProps {
  livingWillFormList: ILivingWillFormPayload['formList'];
  deleteFormDetail: ILivingWillFormPayload['deleteFormStatus'];
  showDeleteModal: IShowDeleteLivingWillFormModal;
  setShowDeleteModal: React.Dispatch<
    React.SetStateAction<IShowDeleteLivingWillFormModal>
  >;
}

const Listing = ({
  livingWillFormList,
  deleteFormDetail,
  showDeleteModal,
  setShowDeleteModal,
}: IProps) => {
  const dispatch = useAppDispatch();

  // switch for delete modal
  const toggleDeleteModal = () => {
    setShowDeleteModal((prev: IShowDeleteLivingWillFormModal) => ({
      ...prev,
      show: !showDeleteModal.show,
      ...(showDeleteModal.show ? { idDetails: [] } : {}),
    }));
  };

  //selected living will
  const selectedLivingWillFormId = (rows: ILivingWillFormRow[]) => {
    setShowDeleteModal((prev: IShowDeleteLivingWillFormModal) => ({
      ...prev,
      idDetails: rows,
    }));
  };

  // handle delete living will Form
  const handleDelete = () => {
    const selectedIds = showDeleteModal.idDetails
      .map((formDetails: ILivingWillFormRow) => formDetails.id)
      .join(',');
    dispatch(deleteLivingWillForm(selectedIds));
  };

  // handle delete open modal
  const openDeleteModal = (row: ILivingWillFormRow) => {
    selectedLivingWillFormId([row]);
    toggleDeleteModal();
  };

  //columns for listing
  const columns: ILivingWillFormColumn[] = [
    {
      id: 'state',
      name: 'State',
      selector: (row: ILivingWillFormRow) => row.state_name,
      sortable: true,
      width: '9.5rem',
    },
    {
      id: 'form_name',
      name: 'State PDF',
      selector: (row: ILivingWillFormRow) => row.page_title,
      sortable: true,
    },
    {
      id: 'actions',
      name: 'Actions',
      selector: (row: ILivingWillFormRow) => row.actions,
      center: true,
      compact: true,
      width: '8.5rem',
      cell: (row: ILivingWillFormRow) => (
        <div className="mx-auto" style={{ display: 'flex', gap: '10px' }}>
          <ActionButton
            action="view"
            redirectTo={`/living-will-forms/${row?.id}/view`}
            permissions={['view-living-will-form-details']}
          />
          <ActionButton
            action="edit"
            redirectTo={`/living-will-forms/${row.id}/edit`}
            permissions={['update-living-will-form']}
          />
          <ActionButton
            action="delete"
            onClick={() => openDeleteModal(row)}
            permissions={['delete-living-will-form']}
            className="ml-1"
          />
        </div>
      ),
      omit: !canUserAccess([
        'view-living-will-form-details',
        'update-living-will-form',
        'delete-living-will-form',
      ]),
    },
  ];

  // handle delete modal close on success delete
  useEffect(() => {
    if (deleteFormDetail?.success) {
      setShowDeleteModal((prev: IShowDeleteLivingWillFormModal) => ({
        ...prev,
        idDetails: [],
      }));
    }
    // eslint-disable-next-line
  }, [deleteFormDetail?.success]);
  return (
    <div className="row mt-3">
      <div className="col-lg-12">
        <Card>
          <Card.Body className="">
            <DataTable
              className="dt-table"
              persistTableHead
              responsive
              progressPending={livingWillFormList.loading}
              keyField="id"
              pagination
              paginationPerPage={50}
              paginationRowsPerPageOptions={PageOptions}
              striped
              highlightOnHover
              defaultSortFieldId="title"
              columns={columns}
              paginationTotalRows={livingWillFormList.data?.total}
              data={livingWillFormList.data?.data}
              customStyles={dtCustomStyle}
            />
          </Card.Body>
        </Card>
        <DeleteModal
          show={showDeleteModal.show}
          selectedIdDetails={showDeleteModal.idDetails}
          toggleDeleteModal={toggleDeleteModal}
          handleSubmit={handleDelete}
          deleteLivingWillFormDetail={deleteFormDetail}
        />
      </div>
    </div>
  );
};

export default Listing;
