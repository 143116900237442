const array = [
  {
    _tag: 'item',
    name: 'Dashboard',
    to: '/',
    icon: 'fa fa-dashboard',
    permissions: ['view-dashboard'],
  },
  {
    _tag: 'item',
    name: 'Death Notices',
    to: '/death-notices',
    icon: 'fa fa-bell',
    permissions: ['view-death-notices'],
  },
  {
    _tag: 'item',
    name: 'Obituaries',
    to: '/obituaries',
    icon: 'fa fa-bell',
    permissions: ['view-obituaries'],
  },
  {
    _tag: 'title',
    name: 'Invoices & Will Searches',
    permissions: ['view-invoices', 'view-missing-will-search'],
  },
  {
    _tag: 'item',
    name: 'Invoices',
    to: '/invoices',
    icon: 'fa fa-usd',
    permissions: ['view-invoices'],
  },
  {
    _tag: 'item',
    name: 'Missing Will Searches',
    to: '/missing-will-searches',
    icon: 'fa fa-file-text',
    permissions: ['view-missing-will-search'],
  },
  {
    _tag: 'title',
    name: 'Users',
    permissions: [
      'view-will-creators',
      'view-will-registrations',
      'view-users',
    ],
  },
  {
    _tag: 'item',
    name: 'Will Creators',
    to: '/will-creators',
    icon: 'fa fa-file',
    permissions: ['view-will-creators'],
  },
  {
    _tag: 'item',
    name: 'Will Registrants',
    to: '/will-registrations',
    icon: 'fa fa-file-text',
    permissions: ['view-will-registrations'],
  },
  {
    _tag: 'item',
    name: 'Admin Users',
    to: '/admin-users',
    icon: 'fa fa-users',
    permissions: ['view-admin-users'],
  },
  {
    _tag: 'title',
    name: 'Charities',
    permissions: ['view-charities'],
  },
  {
    _tag: 'item',
    name: 'Charity Categories',
    to: '/charity-categories',
    icon: 'fa fa-sitemap',
    permissions: ['view-charity-categories'],
  },
  {
    _tag: 'item',
    name: 'Charities',
    to: '/charities',
    icon: 'fa fa-building-o',
    permissions: ['view-charities'],
  },
  {
    _tag: 'item',
    name: 'Partnerships',
    to: '/partnerships',
    icon: 'fa fa-handshake-o',
    permissions: ['view-partnerships'],
  },
  {
    _tag: 'title',
    name: 'Pages & Templates',
    permissions: ['view-pages', 'view-templates', 'view-meta-tags'],
  },
  {
    _tag: 'item',
    name: 'Pages',
    to: '/pages',
    icon: 'fa fa-file',
    permissions: ['view-pages'],
  },
  {
    _tag: 'item',
    name: 'Templates',
    to: '/templates',
    icon: 'fa fa-envelope',
    permissions: ['view-templates'],
  },
  {
    _tag: 'item',
    name: 'Meta Tags',
    to: '/meta-tags',
    icon: 'fa fa-tags',
    permissions: ['view-meta-tags'],
  },
  {
    _tag: 'title',
    name: 'Roles & Permissions',
    permissions: ['view-roles', 'view-permissions'],
  },
  {
    _tag: 'item',
    name: 'Roles',
    to: '/roles',
    icon: 'fa fa-sitemap',
    permissions: ['view-roles'],
  },
  {
    _tag: 'item',
    name: 'Permissions',
    to: '/permissions',
    icon: 'fa fa-sitemap',
    permissions: ['view-permissions'],
  },
  {
    _tag: 'title',
    name: 'Miscellaneous',
    permissions: [
      'view-questions',
      'view-living-will-forms',
      'view-testimonials',
      'view-terminologies',
      'view-faqs',
      'view-pricing',
    ],
  },
  {
    _tag: 'item',
    name: 'Questions',
    to: '/questions',
    icon: 'fa fa-question-circle-o',
    permissions: [''],
  },
  {
    _tag: 'item',
    name: 'Living Will Forms',
    to: '/living-will-forms',
    icon: 'fa fa-file-text',
    permissions: ['view-living-will-forms'],
  },
  {
    _tag: 'item',
    name: 'Testimonials',
    to: '/testimonials',
    icon: 'fa fa-file-text',
    permissions: ['view-testimonials'],
  },
  {
    _tag: 'item',
    name: 'Terminologies',
    to: '/terminologies',
    icon: 'fa fa-book',
    permissions: ['view-terminologies'],
  },
  {
    _tag: 'item',
    name: 'Faqs',
    to: '/faqs',
    icon: 'fa fa-question',
    permissions: ['view-faqs'],
  },
  {
    _tag: 'item',
    name: 'Pricing',
    to: '/pricing',
    icon: 'fa fa-usd',
    permissions: ['view-pricing'],
  },
];

export default array;
